import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

// dynamic image component by querying for files & returning public path of image by fileName
const Image = ({ fileName, alt, style, className }) => {
  const { allFile } = useStaticQuery(graphql`
    query {
      allFile {
        edges {
          node {
            publicURL
            relativePath
          }
        }
      }
    }
  `)
  // hacky way to get the image (as we do this query too many times to do the filtering)
  const image = allFile.edges.find(n => n.node.relativePath === fileName);
  if (!image) return ''
  return (
      <img src={image.node.publicURL} alt={alt} className={className} style={style} />
  )

  // old way that uses sharp

  // const { allImageSharp } = useStaticQuery(graphql`
  //   query {
  //     allImageSharp {
  //       nodes {
  //         fluid(maxWidth: 1600) {
  //           originalName
  //           ...GatsbyImageSharpFluid_withWebp
  //         }
  //       }
  //     }
  //   }
  // `)
  // hacky way to get the image (as we do this query too many times to do the filtering)
  // const image = allImageSharp.nodes.find(n => n.fluid.originalName === fileName);
  // if (!image) return ''

  // const fluid = image.fluid;

  // // including this one makes it blurry initially but handles resizing well i think
  // //<Img fluid={fluid} alt={alt} style={style} />

  // return (
  //     <img src={fluid.src} alt={alt} className={className} style={style} />
  // )
}

export default Image;