import React from "react"
import { Link } from "gatsby"

import Image from "../image"

const Spotlight = ({node, mini}) => {

  const imagesPrefix = '../../images/';

  return (
    <div key={node.frontmatter.slug} className="flex group flex-col rounded-lg shadow-lg overflow-hidden">
      {!mini ? (
        <Link to={node.frontmatter.slug} className="flex-shrink-0">
          {node.frontmatter.imageUrl.startsWith(imagesPrefix) ? (
            <Image className="h-48 w-full object-cover filter grayscale-50 group-hover:grayscale-25" fileName={node.frontmatter.imageUrl.replace(imagesPrefix, '')} alt={node.frontmatter.title} />
          ) : (
            <img className="h-48 w-full object-cover filter grayscale-50 group-hover:grayscale-25" src={node.frontmatter.imageUrl} alt={node.frontmatter.title} />
          )}
        </Link>
      ) : ''}
      <div className="flex-1 bg-gray-800 text-white p-6 flex flex-col justify-between">
        <div className="flex-1">
          {!mini ? (
            <div className="text-sm font-medium text-cyan-300 opacity-50 mb-2">
              <div>
                Customer Spotlight
              </div>
            </div>
          ) : ''}
          <Link to={node.frontmatter.slug} className="block">
            <p className="text-xl font-semibold text-white">{node.frontmatter.title}</p>
            <p className="mt-3 text-base text-gray-300">{node.frontmatter.description}</p>
          </Link>
        </div>
        {true || !mini ? (
          <div className="mt-4 flex items-center">
            {!mini ? (
              <div className="flex-shrink-0 mr-3">
                <div>
                  <span className="sr-only">{node.frontmatter.author}</span>
                  {node.frontmatter.authorImage ? (
                    <img className="h-10 w-10 rounded-full object-cover" src={node.frontmatter.authorImage} alt={node.frontmatter.author} />
                  ) : (
                    <Image className="h-10 w-10 rounded-full object-cover" fileName={'avatar-fed.png'} alt={"Fed from GummySearch"} />
                  )}
                </div>
              </div>
            ) : ''}
            <div className="">
              {!mini ? (
                <div className="text-sm font-medium text-gray-900">
                  <div className="text-white">
                    {node.frontmatter.author}
                  </div>
                </div>
              ) : ''}
              <div className="flex space-x-1 text-sm text-gray-300">
                <time dateTime={node.frontmatter.published}>{node.frontmatter.published}</time>
              </div>
            </div>
          </div>
        ) : ''}
      </div>
    </div>
  );
}

export default Spotlight;
